.container {
    min-height: 64px;
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: flex-start;
    padding: 0.5rem 0.8rem;
    border-bottom: 1px solid rgba(217, 217, 217, 1);

    .icon {
        padding-right: 15px;
        align-self: flex-start;
    }

    .content {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .input {
            flex: 1;
            margin-right: 0.8rem;

            .formItem {
                padding-top: 0.5rem;
            }

            .skeleton {
                height: 1rem;
            }
        }

        .label {
            user-select: none;
            display: block;
        }

        .buttons {
            align-self: flex-end;
            display: flex;
            gap: 0.5rem;
        }
    }

    .actions {
        align-self: center;
        display: flex;
        gap: 0.5rem;
    }
}
/* 
.container:last-child {
    border: none;
} */
